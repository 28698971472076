import React, { useState, useRef, useMemo, useEffect } from 'react';
import './App.css';
import SlowlyTypingInput from "./SlowTyping";

function Cursor() {
  return <span className="cursor">|</span>;
}

function description(value){
  const data = {
    'higher': 'Automation in aircraft engineering.',
    '4 years': 'I started actively studying programming at the end of 2018. Additionally, I had a programming-focused education in school, which provided me with a solid foundation. This knowledge allowed me to start earning income quickly.',
    'blockchain, automation': 'Initially, I was exclusively involved in developing bots for task automation. However, my true passion has always been in blockchain technology. So, after gaining experience in automation, I made the decision to dedicate my life to blockchain.',
    'JavaScript': 'My last blockchain projects are written using JavaScript.',    
    'C#': 'I primarily utilized the C# programming language to develop modules and code snippets for ZennoPoster, with a focus on delivering custom solutions tailored to individual client requirements.',
    'Solidity': 'I used Solidity to create my own contracts for calling swap function on UniV2/UniV3 pair contracts, sometimes — forking and editing other contracts.',
    'Yul': 'To reduce gas cost I decided to use Yul for `inline assembly` inside Solidity.',
    'SQL': 'I found that .txt files were not sufficient for storing and retrieving values for my bots, so I actively incorporated MySQL/SQLite databases into my projects and frequently offered this solution to my clients.',
    'GraphQL': 'I have experience in extracting NFT prices using unofficial DEXs GraphQL APIs and retrieving a large volume of filtered data from Go Ethereum.',
    'Python': 'I used Python to create a bot with telegram alerts for OpenSea.',
    'Java': 'Java is very similar to C#, so I have periodically used Java successfully to edit scripts in Fiddler. This includes tasks such as spoofing requests and using a proxy.',
    'Go': 'I recently made a few modifications to Go Ethereum in order to improve the reception of transactions from the mempool and to enhance some API functions.',
    'PHP': 'I have some experience in creating and editing landing pages for affiliate marketing.',
    'HTML': 'I have some experience in creating and editing landing pages for affiliate marketing.',
    'CSS': 'I have some experience in creating and editing landing pages for affiliate marketing.',
    
    'Ethers.js': 'The basic library that I use for blockchain development.',
    'web3.js': 'I have prior experience using it for development.',
    'Solc': 'I have experience using it for compiling smart contracts.',
    'Nethereum': 'I have used Nethereum to interact with APIs in the past while working with ZennoPoster.',
    'ReactJS': 'This website is built using ReactJS. ;)',           
    'Chillkat': 'I used it for making HTTP requests and working with email.',	
    'FidlerCore': 'Interception of HTTP/HTTPS requests.',	
    'AForge': 'I used it to solve captchas.',	
    'other libraries': 'When developing software, I seek the fastest and most efficient ways to solve tasks. Therefore, I often utilize various libraries not listed here.',

    'Ethereum': 'I have experience constructing body requests to interact with APIs. This is typically necessary when the required functionality is not available in the provided libraries. However, most of the time, I use libraries for convenient interaction.',
    'Telegram': 'I actively use it for convenient monitoring of trading bot transactions.',		
    'Private Relays': 'I use bundles for sending transactions on the blockchain.',
    'Trading Platforms': 'If necessary, I use official/unofficial APIs for CEX/DEX and NFT marketplaces.',
    'Social Networks': 'I have experience working with unofficial APIs for Discord, Twitter, Reddit, Youtube, Google, Facebook, Instagram, TikTok, and others. This includes account creation, content uploading, message sending, and more.',				
    'Win32': 'I used it for automating actions on Windows.',
    
    'Go Ethereum': 'I use this client for interacting with the Ethereum blockchain. I modify it to suit my needs.',
    'Hardhat': 'I actively use it for debugging bots in specific blocks (forking). I previously used it for deploying smart contracts.',
    'Remix IDE': 'My favorite IDE for working with smart contracts!',
    'Truffle': 'I previously used it for deploying smart contracts.',
    'Ganache': 'I previously used it for debugging smart contracts.',
    'ZennoPoster': 'I have extensive experience. It is a convenient tool for quick task automation.',
    'Fiddler': 'In my opinion, one of the most convenient tools for monitoring HTTP/HTTPS/WS requests.I have extensive experience working with it.',
    'other tools': 'During development, I periodically turn to the use of various auxiliary tools to increase efficiency.',
    
    'MySQL': 'I have extensive work experience, but currently, I use PostgreSQL or SQLite for similar small-scale databases.',
    'SQLite': 'Actively using it.',
    'MongoDB': 'Actively using it for logging my bots. It is very convenient for monitoring and analysis.',
    'PostgreSQL': 'Actively using it for storing data, such as information about pairs, tokens, and much more.',
    
    'front-run based trading bots (MEV)': 'I was able to make profit using several strategies.',
    'NFT trading bots': 'I was able to make profit using several strategies.',
    'proxifier for Android emulators': 'This auxiliary software is successful. I have made quite a few sales.',
    'social networks bots': 'Bots for promoting digital products on social media.',
    'captcha solvers': 'My solution has been successful with many clients.',
    'other cases':'I have developed a lot of bots to assist others in earning online. Many of them have shared truly impressive results with me.',

    'communication': 'I love people, and I enjoy communicating with them.',
    'teamwork': 'I have some experience working in a team.',
    'problem-solving': 'It is a kind of challenge for me. I get the maximum satisfaction when the task is solved.',
    'critical thinking': 'I actively use this skill in development.',
    'decision-making': 'Before making a decision, it is important to conduct an analysis and consider all possible solutions to the problem. Otherwise, one may face a failure.',
    'stress management': 'It is important to know how to avoid stress in order to be effective and full of energy.',
    'adaptability': 'An important skill in the modern world. ;)',
    'creativity': 'Also important skill in the modern world :)',
    'openness to criticism': 'Often, it is necessary to listen to the client\'s suggestions for software improvements.',
    'feedback from clients available upon request. ;)': 'Just PM me. :) The link to my contact on Telegram is at the top of the page.',
    
    '2015-2016': 'It was affiliate marketing using social media advertising accounts.',
		'2016-2023': 'Client order fulfillment. Developing software for sale and generating profit.'
    
  }
  return data[value]
}

function Data({ dataKey,data }) {
  const [expanded, setExpanded] = useState(false);
  
  const ref = useRef(null);
  useEffect(() => {
    const handleMouseEnter = () => {
      setExpanded(true);
    };
    const handleMouseLeave = (event) => {
      const { clientY } = event;
      if (clientY < 150) {
        setExpanded(false);        
      }
    };
    if (ref.current) {
      ref.current.addEventListener('mouseenter', handleMouseEnter);
      ref.current.addEventListener('mouseleave', handleMouseLeave);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('mouseenter', handleMouseEnter);
        ref.current.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [ref]);
  
  const aqua = '#00c4df';
  const white = 'white';
  
  const [isHovered, setIsHovered] = useState(Array.from({ length: data.length }, () => false));
  const [colors, setColors] = React.useState(Array.from({ length: data.length }, () => 'white'));
  const updatedColors = [...colors];
  let hovered = [...isHovered]
  
  const handleMouseEnterLine = useMemo( () => index => {
    updatedColors[index] = aqua;
    setColors(updatedColors);
    hovered[index] = true;
    setIsHovered(hovered)
  });

  const handleMouseLeaveLine = useMemo( () => index => {
    updatedColors[index] = 'white';  
    setColors(updatedColors);
    hovered[index] = false;
    setIsHovered(hovered)
  });

  // const [isHovered, setIsHovered] = useState(false);
  // console.log(dataKey)
  // console.log(data) onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
  //style={{marginLeft: '100px'}}
  return (
    <div class="Space1" key={dataKey} ref={ref} > 
      {expanded ? (         
        <div>        
        {dataKey.replace('{ ... }','')}       
        <span style={{color: aqua}}>{'{'}</span>        
        {Object.keys(data).map((key,index) => (                                    
              typeof data[key] == 'object' ? (
                  <Data key={key} dataKey={`${key}: { ... }`} data={data[key]} />
              ):(                
                key.match(/\d/) ? (
                  <div key={key}>          
                    <div
                      key={key}
                      class="Space1"
                      style={{ color: colors[index] }}
                      onMouseEnter={() => {
                        handleMouseEnterLine(index)
                        }
                      }
                      onMouseLeave={() => {
                        handleMouseLeaveLine(index)
                        }
                      }
                    >
                      {data[key]}                                                         
                    </div> 
                    {isHovered[index] && (
                      <span key={data[key]} class="Text">                        
                        <SlowlyTypingInput text={description(data[key])} />
                      </span>
                    )}   
                  </div>               
                  
                ) : ( data[key].includes('https') ? 
                  <div style={{color: white}} key={key}>                    
                    <a class = "Space1" style={{ color: colors[index]}}
                    onMouseEnter={() => {
                      handleMouseEnterLine(index)
                      }
                    }
                    onMouseLeave={() => {
                      handleMouseLeaveLine(index)
                      }
                    } href={data[key]}>{key}</a>
                  </div> :
                  <div style={{color: white}} key={key}>   
                    <div 
                    key={key}
                    class = "Space1"
                    style={{ color: colors[index]}}
                    onMouseEnter={() => {
                      handleMouseEnterLine(index)
                      }
                    }
                    onMouseLeave={() => {
                      handleMouseLeaveLine(index)
                      }
                    }>{`${key}:  `}<span>{`${data[key]}`}</span></div>
                    {isHovered[index] && (
                      <span key={data[key]} class="Text">
                        <SlowlyTypingInput text={description(data[key])} />
                      </span>
                    )} 
                  </div>
                )
              )  
                        
          ))
          }
          <span style={{color: aqua}}>{'}'}</span>
        </div>
      ) : (        
        dataKey.includes('{ ... }') ? (
        <div style={{color: white}} >
        {dataKey.replace('{ ... }','')}        
        {/* <div style={{color: grey}}>{dataKey.replace('{ ... }','')}</div> */}
          <span style={{color: aqua}}>{'{'}</span> ... <span style={{color: aqua}}>{'}'}</span>        
        </div>
        ) : (
          <div>{dataKey}</div>
        )
      )}      
    </div>
    
  );
}

const DownloadIcon = (color) => {
   
  return (
    <svg fill={color} preserveAspectRatio="xMidYMid meet" width='32px' height='32px' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="-0.595 31 69 50.145">
      <g transform="matrix(1, 0, 0, 1, -16.513558, 4.120969)">
        <path d="M83.388,63.888c-0.829,0-1.5,0.671-1.5,1.5v18.5h-63v-18.5c0-0.829-0.671-1.5-1.5-1.5s-1.5,0.671-1.5,1.5v20 c0,0.829,0.671,1.5,1.5,1.5h66c0.829,0,1.5-0.671,1.5-1.5v-20C84.888,64.56,84.217,63.888,83.388,63.888z"/>
        <path d="M 49.328 69.449 C 49.621 69.742 50.005 69.888 50.389 69.888 C 50.773 69.888 51.157 69.742 51.45 69.449 L 64.45 56.449 C 65.036 55.864 65.036 54.913 64.45 54.328 C 63.864 53.742 62.915 53.742 62.329 54.328 L 51.89 64.767 L 51.89 38.243 C 51.89 37.414 51.219 36.743 50.39 36.743 C 49.561 36.743 48.89 37.414 48.89 38.243 L 48.89 64.767 L 38.451 54.328 C 37.865 53.742 36.916 53.742 36.33 54.328 C 35.744 54.913 35.744 55.864 36.33 56.449 L 49.328 69.449 Z"/>
      </g>
    </svg>
  );
};

class App extends React.Component {
  state = {
    textColor: 'white',
    showText: false,
    color: 'white' 
  }
  
  handleMouseEnter = () => {
    this.setState({ textColor: '#00c4df', showText: true })
  }

  handleMouseLeave = () => {
    this.setState({ textColor: 'white', showText: false })
  }

  handleMouseEnter2 = () => {
    this.setState({ color: '#00c4df' })
  }

  handleMouseLeave2 = () => {
    this.setState({ color: 'white' })
  }

  render() {
  return (   
    <div className="App">
      <div class="Space1"
      style={{color: 'white'}}
      >contact: <a href="https://t.me/xerenity"
        style={{color: this.state.textColor, textDecoration: 'none'}}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >t.me/xerenity</a></div>
      {this.state.showText && (
        <span style={{ width: '40%', textAlign: 'left', position: 'fixed', right: '10%', top: '40%' }}><SlowlyTypingInput text='Feel free to PM me ;)' /></span>
      )}
      <Data dataKey={'aboutMe { ... }'} data={{ 
        experience: '4 years', specialization: 'blockchain, automation', education: 'higher',
        hardSkills: { 
          languages: [
            'JavaScript',
					  'C#',
            'Solidity',
            'Yul',
            'SQL',
            'GraphQL',
            'Python',
            'Java',
            'Go',
            'PHP',
            'HTML',
            'CSS'
          ],
          libraries: [
            'Ethers.js',
            'web3.js',
            'Solc',
            'Nethereum',
            'ReactJS',            
            'Chillkat',	
            'FidlerCore',
            'AForge',
            'other libraries'            
          ],
          APIs: [
            'Ethereum',
            'Telegram',		
            'Private Relays',
            'Trading Platforms',
            'Social Networks',				
            'Win32'
          ],
          tools: [
            'Go Ethereum',
            'Hardhat',
            'Remix IDE',
            'Truffle',
            'Ganache',
            'ZennoPoster',
            'Fiddler',
            'other tools'           
          ],
          DBs: [
            'MySQL',
            'SQLite',
            'MongoDB',
            'PostgreSQL'
          ],
        },
        cases: [
          'front-run based trading bots (MEV)',
          'NFT trading bots',
          'proxifier for Android emulators',          
          'social networks bots',
          'captcha solvers',
          'other cases'
        ],
        softSkills: [
          'communication',
          'teamwork',
          'problem-solving',
          'critical thinking',
          'decision-making',
          'stress management',
          'adaptability',
          'creativity',
          'openness to criticism',
          'feedback from clients available upon request. ;)'
        ],
        
        employmentHistory: {
          marketingAgency: '2015-2016',
		      freelance: '2016-2023'
        }}} />
      <Data dataKey={'links { ... }'} data={{ 
          Twitter: 'https://twitter.com/intent/follow?screen_name=xerenity_dev',
          GitHub: 'https://github.com/Xerenity',
          Medium: 'https://medium.com/@xerenity',
          Youtube: 'https://www.youtube.com/@xerenity_dev',
          Bitcointalk: 'https://bitcointalk.org/index.php?topic=5469207.0',
          TelegramChat: 'https://t.me/xerenity_chat',
          TelegramChannel: 'https://t.me/xerenity_dev'
          
        }} />
      <div><Cursor /></div>
    </div> 
  );
  }
}

export default App;