import React from 'react';
import './SlowlyTypingInput.css';

class SlowlyTypingInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      cursorVisible: true
    };
  }

  componentDidMount() {
    this.typeInput();
    this.blinkCursor();
  }

  typeInput() {
    let { text } = this.props;
    if (text === undefined) text = ''
    const lines = text.split('\n');
    let currentLine = 0;
    let currentIndex = 0;
  
    const typingInterval = setInterval(() => {
      let inputValue = "";
  
      // Add all previous lines
      for (let i = 0; i < currentLine; i++) {
        inputValue += lines[i] + '\n';
      }
  
      // Add the current line
      inputValue += lines[currentLine].substring(0, currentIndex);
  
      this.setState({
        inputValue
      });
      currentIndex++;
      if (currentIndex > lines[currentLine].length) {
        currentLine++;
        currentIndex = 0;
        if (currentLine >= lines.length) {
          clearInterval(typingInterval);
        }
      }
    }, 5);
  }
  

  blinkCursor() {
    this.cursorBlinkingInterval = setInterval(() => {
      this.setState(prevState => ({
        cursorVisible: !prevState.cursorVisible
      }));
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.cursorBlinkingInterval);
  }
  render() {
    const { inputValue, cursorVisible } = this.state;
    return (
      <div className="SlowlyTypingInput">
        {inputValue}
        {cursorVisible && <span>|</span>}
      </div>
    );
  }
}


export default SlowlyTypingInput;
